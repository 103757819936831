import React, {useCallback, useRef} from "react";
import Layout from "../layouts/en";
import { scrollElementIntoView } from "../components/helper";
import { Col, Container, Row } from "reactstrap";
import woocommerce from "../assets/icons/icons8-woocommerce-color.svg";
import magento from "../assets/icons/icons8-magento-color.svg";
import shopify from "../assets/icons/icons8-shopify-color.svg";
import advantage1 from "../assets/icons/emojis/desktopcomputer.png";
import advantage2 from "../assets/icons/emojis/einkaufswagen.png";
import advantage3 from "../assets/icons/emojis/mann-am-serviceschalter.png";
import slider1 from "../images/screenshot-steele-meals.png";
import slider2 from "../images/screenshot-kickass-by-laura-philipp.png";
import slider3 from "../images/screenshot-my-source-nutrition.png";
import step1 from "../images/pexels-photo-1181715-compressor.jpeg";
import step2 from "../images/pexels-photo-5077047-compressor.jpeg";
import step3 from "../images/pexels-photo-1181244-compressor.jpeg";
import step4 from "../images/pexels-photo-1251855-compressor.jpg";
import header from "../images/istock-510591412-compressor.jpg";
import SectionContact from "../components/sections/sectioncontact";
import Collapsible from "react-collapsible";
import {throttle} from "lodash";

function Ecommerce(props) {
  const scrollTop = useRef(0);
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  const resizeListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  const scrollListener = useCallback(() => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (document.getElementById('page-slider')) {
      let scroll = document.getElementById('page-slider').getBoundingClientRect();

      if (scroll.top <= window.innerHeight && scroll.top >= -scroll.height) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let elementWidth = scroll.width;
        let elementHeight = scroll.height;
        if (st > scrollTop.current){
          scroll = scroll.bottom;
        } else {
          scroll = scroll.bottom;
        }
        let elementWidthTotal = 0;
        if (windowWidth >= 992) {
          elementWidthTotal = (elementWidth - 716) * 3 + 2 * 24;
        } else if (windowWidth >= 576) {
          elementWidthTotal = (elementWidth - 258) * 3 + 2 * 24;
        } else {
          elementWidthTotal = (elementWidth - 80) * 3 + 2 * 16;
        }
        let difference = elementWidthTotal - elementWidth;
        let heightTotal =  windowHeight + elementHeight;
        let percentage = scroll / heightTotal;
        if (percentage >= 0 && percentage <= 1) {
          let reversePercentage = 1 - percentage;
          if (st > scrollTop.current){
            document.getElementById('page-slider').style.transform = 'translateX(-' + (reversePercentage * difference) + 'px)';
          } else {
            document.getElementById('page-slider').style.transform = 'translateX(-' + (difference - (percentage * difference)) + 'px)';
          }
        }
      }
    }
    scrollTop.current = st <= 0 ? 0 : st;
  }, []);
  const loadListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
    window.addEventListener('resize', throttle(resizeListener, 200));
    window.addEventListener('load', loadListener);
    window.addEventListener('scroll', throttle(scrollListener, 20));
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', loadListener);
    }
  }, []);

  return (
    <Layout classNameChild="ecommerce" location={props.location} title="E-commerce agency from Karlsruhe | newww.agency" description="As an e-commerce agency from Karlsruhe, we build your individual online shop. Experts in consulting, design, development and support of websites and landing pages." keywords="Online-Shop, Conception, UX/UI-Design, Development, Shopware, Magento, Shopify, WooCommerce, " robots="index, follow, notranslate">
      <div className="section section-dark section-header" id="ecommerce">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="header-subheadline">Individual online shop</h3>
                <h1 className="header-headline">As an e-commerce agency, we bring your products to the internet.</h1>
                <a className="create-contact dark" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" style={{backgroundImage: `url(${header})`}} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-tripple" id="advantages">
        <Container>
          <h2 className="headline">Your individual online shop exactly as you imagine it.</h2>
          <p className="description">Our services include the conception, design and development of flexible, powerful, and user-friendly e-commerce solutions that inspire customers and increase sales.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage1})`}}/>
                <h3 className="box-title">100% custom design</h3>
                <p className="box-description">Together with you, we will develop your individual online shop exactly as you imagine it and bring your products to life.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage2})`}}/>
                <h3 className="box-title">Maximum flexibility</h3>
                <p className="box-description">We build your online shop in such a way that you then encounter maximum flexibility and can easily make adjustments.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage3})`}}/>
                <h3 className="box-title">All from a single source</h3>
                <p className="box-description">With us you get all services from a single source. We think of all our projects holistically and can do everything that has to do with the web.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-slider" id="slider">
        <Container>
          <h2 className="headline">We love our customers and work closely with them.</h2>
          <p className="description">In each of our projects, we rely on close cooperation with our customers. The focus is always on the future user, whom we want to offer a unique shopping experience.</p>
          <div className="slider" id="page-slider">
            <div className="slider-img" style={{backgroundImage: `url(${slider1})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider2})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider3})`}}/>
          </div>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-knowledge" id="knowledge">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="knowledge-subheadline">Did you know that …</h3>
                <h2 className="knowledge-headline">76% of Germans shop online at least once a month.</h2>
                <p className="knowledge-paragraph">
                  Online trading is booming and is becoming more and more important. Over three quarters of Germans shop online at least once a month. Experts even assume that online retail will account for 95% of total sales by 2040. So it's time to deal with the topic.
                </p>
                <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark section-line" id="line">
        <Container>
          <h2 className="headline">For us, an online shop project only takes a few weeks to go live.</h2>
          <p className="description">We use agile and efficient processes in all of our projects. For us, an online shop project usually only takes a few weeks to go live. Our teams work hand in hand without any frictional losses.</p>
          <Row>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step1})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Screening</h4>
                <p className="box-description">In the first step, we deal intensively with your project, plan the project, analyze your competitors, and evaluate customer needs.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step2})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">UX / UI design</h4>
                <p className="box-description">It continues with the design of your online shop, in which you actively participate and give your input. The result is a detailed screen design for all end devices.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step3})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Development</h4>
                <p className="box-description">Then our developers start with the implementation and give full throttle. As soon as the individual pages and functions are ready, they will be tested intensively.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step4})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Go-Live</h4>
                <p className="box-description">Once all the content and plugins have been entered, you can start working with your shop. Of course, we also look after our customers afterwards.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-tripple" id="technologies">
        <Container>
          <h2 className="headline">We work with the best shop systems there are.</h2>
          <p className="description">Whether Magento, Shopify or Woo-Commerce, we work with the best shop systems available and are geared towards our customers. Our goal is always to develop shops that offer maximum flexibility.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${magento})`}}/>
                <h3 className="box-title">Magento</h3>
                <p className="box-description">Magento has been one of the leading shop systems for many years. Due to its wide range of options, it is particularly popular in the B2B business.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${shopify})`}}/>
                <h3 className="box-title">Shopify</h3>
                <p className="box-description">Shopify supports over 1,000,000 companies worldwide and is characterized by its simplicity and flexibility.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${woocommerce})`}}/>
                <h3 className="box-title">WooCommerce</h3>
                <p className="box-description">With 15 million downloads, WooCommerce is one of the most popular and widely used Wordpress plugins worldwide.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-faq" id="faq">
        <Container>
          <h2 className="headline">Frequently asked questions (FAQ)</h2>
          <Row>
            <Col lg="12" className="faq-wrapper">
              <ul>
                <li>
                  <Collapsible trigger="How long does an online shop project take?">
                    <p>
                        The duration of an online shop project depends on several different factors. One of the essential roles here is whether an existing theme is used, or an individual design is implemented. As a rule, however, our online shop projects only last a few weeks.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How much does an online shop cost?">
                    <p>
                      In all our projects, we follow the budget of our customers and make it our task to get the most out of the means available. Our usual hourly rate is € 80.00 plus VAT. The costs therefore always depend on the complexity and scope of the project.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How does an online shop project work?">
                    <p>
                      We start each of our projects with an intensive screening by carefully examining your project. It continues with the UX / UI design. This is where the concept is worked out, which the developers then implement one-to-one. Once the content has been entered and everything has been tested, product sales can begin.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Which online shop systems are used?">
                    <p>
                      We are flexible when it comes to the technologies we use, you focus on our customers. Shopify, which is characterized by its simplicity, is particularly popular with us. As a Shopify partner, we also develop our own themes and plugins for our customers, for example.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Where does newww.agency offer its services?">
                    <p>
                      Our headquarters are in Karlsruhe. Our customers, on the other hand, are at home all over the world. We look after our customers across all locations and have established processes that enable flexible and smooth cooperation.
                    </p>
                  </Collapsible>
                </li>
              </ul>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <SectionContact lang='en' />
    </Layout>
  );
}

export default Ecommerce;
